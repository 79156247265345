/* import the Bootstrap */
@import "bootstrap"

/* ------- customize primary button color -------- */
$mynewcolor: #B3D8F2
//.search
//  width: 50%
//  margin: auto

//.right
//  float: right
//  width: 50%

.padding
  padding-top: 15px !important

.btn-outline-primary
  @include button-outline-variant(#404EA1, #F04923, lighten($mynewcolor,5%), $mynewcolor)
