.header
  border-bottom: #404EA1 solid 3px
  //background: #58748f

.img-header
  //opacity: 0.7
  height: auto
  width: 300px !important
  margin: 10px !important
  cursor: pointer

