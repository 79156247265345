.bg-color
  color: #404EA1

.row-margin
  margin-bottom: 20px

.tooltip-clr
  background-color: #B3D8F2 !important
  opacity: 1
  color: black !important

.z-pos
  z-index: 1

button.bg-color:hover, a.bg-color:hover
  color: #F04923 !important

.small-txt
  font-size: 0.9em
  //margin: 4px

.align-txt
  text-align: justify

.margin-text
  margin: 5px

.pdg
  padding: 10px

.orange-color
  color: #F04923

//tr
//  border-bottom: 1pt solid black

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th
  background-color: whitesmoke !important

u.bg-color:hover
  color: #F04923 !important
  cursor: pointer

.my-badge
  background-color: #B3D8F2
  color: #F04923
  font-weight: normal
  padding: 4px
  //padding: 4px 8px
  text-align: center
  border-radius: 5px

.extractive_summary_bold
  font-weight: bold
  background-color: transparent
  //z-index: 1


//span[id^=extreme_summary] > .bold:hover /* child selector */
//  color: green !important

.cursor
  cursor: pointer

.col-with-border
  border-right: 1px solid black
  //padding: 10px
