.icn-color
  color: #404EA1

.icn-color:hover
  color: #F04923
  cursor: pointer

.pdng
  padding: 20px

.right-button
  position: absolute
  left: 0
  float: right !important


