.bg-color
  color: #404EA1

.mgn-top
  margin-top: 20px

.padding-text
  padding-left: 20px
  padding-right: 10px
  padding-top: 45px

.img-home2
  //margin-top: 20px
  opacity: 1
  height: 200px
  width: auto
  padding-left: 45px

.flip-horizontal
  transform: scaleX(-1)
  display: inline-block
  //white-space: nowrap

.img-pointer
  cursor: pointer
