.rc-footer-container
  padding: 30px 0 20px !important
  line-height: 1 !important

div > h2
  color: #404EA1 !important

div > a
  color: #404EA1 !important
  text-decoration: underline !important

div > a:hover
  color: #F04923 !important
