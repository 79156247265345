.bg-color
  color: #404EA1 !important
  //color: #58748f !important

.tooltip-clr
  background-color: #B3D8F2 !important
  opacity: 1
  color: black !important

.copy-icon
  float: right

.rt-pos
  float: right
  margin: 10px

.orange-clr
  color: #F04923

.inline-div
  display: inline-block
  //font-weight: bold

a.bg-color:hover
  color: #F04923
  cursor: pointer

.highlight
  color: #F04923
  background-color: #B3D8F2
  font-size: 0.9em
  border-radius: 5px

.switch-btn
  float: right
  margin-left: 0px
  //padding-bottom: 30px

.font-sz
  font-size: 0.6em
  font-weight: bold

//datalist
//  display: flex
//  flex-direction: column
//  justify-content: space-between
//  writing-mode: vertical-lr
//  width: 200px

//option
//  padding: 0

//.accordion-layout
//  margin-top: 10px

ul
  list-style: none
  /* Remove default bullets
  li::before
    content: "\2022"
    /* Add content: \2022 is the CSS Code/unicode for a bullet
    color: #404EA1
    //color: #58748f
    /* Change the color
    font-weight: bold
    /* If you want it to be bold
    display: inline-block
    /* Needed to add space between the bullet and the text
    width: 1em
    margin-left: -1em

.panel-bg-clr
  margin: 10px
  background: #fff

//.panel-bg-clr:hover
//  background-color: #ddd

.r-margin
  margin-left: 3px
  margin-right: 3px

.accord-margin
  margin: 5px

.span-ht-clr
  display: inline-block
  min-height: 2em
  //background-color: #fff

.sentence-tooltip-style
  width: 50% !important

////**
////* ----------------------------------------------
////* Demo styles
////* ----------------------------------------------
////**
//.accordion
//  border: 1px solid cadetblue
//  border-radius: 2px
//  padding-left: 10px
//
//
//.accordion__item + .accordion__item
//  border-top: 1px solid rgba(0, 0, 0, 0.1)
//
//
.accordion__button
  //background-color: whitesmoke
  color: #404EA1 !important
  cursor: pointer
  padding: 18px
  width: 100%
  text-align: left
  border: white solid !important
  border-radius: 5px

.accordion-item-clr
  background-color: white
  //border: black solid 1px !important

.accordion__button:hover
  background-color: #B3D8F2 !important
  color: #404EA1 !important

//.tooltip
//  //position: relative
//  //display: inline-block
//  border-bottom: 1px dotted black
//
//.tooltip > .tooltiptext
//  visibility: hidden
//  width: 120px
//  background-color: black
//  color: #fff
//  text-align: center
//  border-radius: 6px
//  padding: 5px 0
//
//  /* Position the tooltip */
//  position: absolute
//  z-index: 1
//
//.tooltip:hover .tooltiptext
//  visibility: visible

.clickable-text
  //color: #404EA1
  cursor: copy

span.clickable-text:hover
  color: #F04923

//
//.accordion__button:before
//  display: inline-block
//  content: ''
//  height: 10px
//  width: 10px
//  margin-right: 12px
//  border-bottom: 2px solid currentColor
//  border-right: 2px solid currentColor
//  transform: rotate(-45deg)
//
//
//.accordion__button[aria-expanded='true']::before,
//.accordion__button[aria-selected='true']::before
//  transform: rotate(45deg)
//
//
//[hidden]
//  display: none
//
//
//.accordion__panel
//  padding: 20px
//  animation: fadein 0.35s ease-in
//
//
///* -------------------------------------------------- */
///* ---------------- Animation part ------------------ */
///* -------------------------------------------------- */
//
//@keyframes fadein
//  0%
//    opacity: 0
//  100%
//    opacity: 1
//
//
