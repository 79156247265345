.bg-color
  color: #404EA1

.lbl
  margin-top: 20px
  padding-right: 5px
  color: #404EA1
  font-size: 0.9em

.hits
  //margin-top: 150px
  //padding: 5px
  color: #404EA1
  font-size: 0.8em
  border-left: 1px solid #404EA1
  border-right: 1px solid #404EA1

.select
  height: 30px !important
  width: 120px !important
  margin-top: 15px !important
  color: #404EA1
  border-color: #B3D8F2 !important
  border-radius: 5px
  background-color: #B3D8F2
  cursor: pointer

select.select:focus
  outline-color: #404EA1

.right-button
  float: right

.mgn-text
  margin-left: 5px

.mgn-top
  margin-top: 20px

.mgn-btm
  margin-bottom: 5px

.padding-text
  padding-left: 20px
  padding-right: 10px
  padding-top: 45px

.img-home
  //margin-top: 20px
  opacity: 1
  height: 200px
  width: auto
  padding-left: 14px
  padding-right: 0






